import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { textdb } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './BookAppointment.css';

const BookAppointment = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(textdb, "appointments"), {
        ...formData,
        createdAt: new Date()
      });
      toast.success("Appointment booked successfully!");

      setFormData({
        name: '',
        email: '',
        phone: ''
      });

      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error("Error submitting appointment:", error);
      toast.error("Failed to book appointment. Please try again.");
    }
    setLoading(false);
  };

  return (
    <div className="book-appointment-container">
      <div className="book-appointment-content">
        {/* Left Form Card */}
        <div className="appointment-card">
          <h2 className="form-heading">Book A Consultency</h2>
          <p className="appointment-description">
            Our team is here to help you design your dream space. Kindly fill out the form and we’ll get in touch soon.
          </p>
          
          <form onSubmit={handleSubmit} className="appointment-form">
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                required
              />
            </div>

            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Sending..." : "Book Now"}
            </button>
          </form>
        </div>

        {/* Right Side Info & Image */}
        <div className="info-section">
          <div className="info-content">
            <h1 className="main-heading">
              Let’s Design Your Dream Space
            </h1>
            <p className="sub-heading">
              We blend creativity and functionality to create interiors that tell your story.
            </p>
            <div className="clinic-image">
              <img 
                src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743768235/hwh8d4lbolmzk0piyua7.webp" 
                alt="Interior Design Showcase"
              />
              <div className="image-dots"></div>
              <div className="image-stars">
                <span className="star">✦</span>
                <span className="star">✦</span>
                <span className="star">✦</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BookAppointment;
