// LightDisplay.js
import React, { useState } from 'react';
import PendantLights from './PendantLights';
import styles from './LightDisplay.module.css';

function LightDisplay() {
  const [isLightOn, setIsLightOn] = useState(false);

  const handleToggle = () => {
    setIsLightOn(prevState => !prevState);
  };

  // Define the paragraph text
  const paragraphText = "With just one click, transform your home into a glowing masterpiece. Our stunning pendant lights add warmth, elegance, and charm—perfectly designed to elevate every corner of your living space.";

  return (
    <div className={styles.displayContainer}>
      {/* Left Side: The Pendant Light */}
      <div className={styles.lightColumn}>
        <PendantLights isOn={isLightOn} />
      </div>

      {/* Right Side: Heading, Paragraph, and Switch */}
      <div className={styles.controlsColumn}>

        {/* New Heading Structure */}
        <div className={styles.headingContainer}>
          <span className={`${styles.headingLine} ${styles.colorGold}`}>
          One click away from lighting up your home like this pendant of glow!
          </span>
          <span className={`${styles.headingLine} ${styles.colorTeal}`}>
            
          </span>
          <span className={`${styles.headingLine} ${styles.colorGold}`}>
            
          </span>
        </div>

        {/* New Paragraph */}
        <p className={styles.subParagraph}>
          {paragraphText}
        </p>

        {/* Realistic Toggle Switch */}
        {/* Optional: Wrap switch for better spacing control if needed */}
        <div className={styles.switchWrapper}>
            <div
                className={`${styles.switchTrack} ${isLightOn ? styles.on : ''}`}
                onClick={handleToggle}
                role="switch"
                aria-checked={isLightOn}
                tabIndex="0"
                onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleToggle(); }}
            >
              <div className={`${styles.switchKnob} ${isLightOn ? styles.on : ''}`}></div>
            </div>
        </div>

      </div>
    </div>
  );
}

export default LightDisplay;