import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './Testimonials.css';

const testimonialImage = 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1724669252/fwrzko8n0kd8qnfg3iex.svg';

const testimonialData = [
  {
    id: 1,
    name: 'Aarav Mehta',
    role: 'Homeowner',
    rating: 5,
    image: testimonialImage,
    text: 'Elysian Interiors designed my living room beautifully, striking the perfect balance between elegance and comfort. Their professionalism and attention to detail made the entire process effortless. Highly recommended!'
  },
  {
    id: 2,
    name: 'Megha Tandon',
    role: 'Homeowner',
    rating: 5,
    image: testimonialImage,
    text: 'My kitchen redesign by Elysian Interiors was fantastic. The modular setup is both stylish and functional. The team truly understood my needs and delivered everything on time.'
  },
  {
    id: 3,
    name: 'Simran Kapoor',
    role: 'Business Owner',
    rating: 5,
    image: testimonialImage,
    text: 'Elysian Interiors transformed my office into a modern, inspiring space. From the furniture to the lighting, every detail was perfect. Their expertise truly made a difference.'
  },
  {
    id: 4,
    name: 'Rohan Sharma',
    role: 'Apartment Owner',
    rating: 5,
    image: testimonialImage,
    text: 'I wanted a contemporary yet warm apartment design, and Elysian Interiors delivered. The execution was seamless, exceeding all expectations. Their craftsmanship is truly unmatched!'
  },
  {
    id: 5,
    name: 'Nisha Patel',
    role: 'Interior Lover',
    rating: 5,
    image: testimonialImage,
    text: 'Absolutely love the work done by Elysian Interiors! They transformed my ordinary bedroom into a luxurious retreat.'
  },
  {
    id: 6,
    name: 'Priya Lakhani',
    role: 'Boutique Owner',
    rating: 5,
    image: testimonialImage,
    text: 'I hired Elysian Interiors to design my boutique, and they did an amazing job. The space looks elegant and inviting to customers.'
  },
  {
    id: 7,
    name: 'Shaksham Khurana',
    role: 'Villa Owner',
    rating: 5,
    image: testimonialImage,
    text: 'I can’t thank Elysian Interiors enough for their incredible work on my villa. They seamlessly blended luxury with comfort.'
  }
];

const Testimonial = () => {
  return (
    <div className="testimonial-section">
      <div className="testimonial-stats">
        <h2 className="testimonial-heading">What Our Clients Say</h2>
        <div className="stats-box">
          <h2>1000+</h2>
          <p>Projects Completed</p>
          <img src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1741854571/dt7yqgvl59loltv7mgrp.png" alt="Google" className="platform-logo" />
          <div className="rating">
            <div className="stars">★★★★★</div>
            <p>Rated 4.9</p>
          </div>
        </div>
      </div>

      <div className="testimonial-slider-container">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          breakpoints={{
            640: { slidesPerView: 1 },
            1024: { slidesPerView: 3 }
          }}
          className="testimonial-slider"
        >
          {testimonialData.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="testimonial-card">
                <div className="testimonial-header">
                  <img src={testimonial.image} alt={testimonial.name} className="user-image" />
                  <div className="user-info">
                    <h3>{testimonial.name}</h3>
                    <div className="rating">★★★★★</div>
                    <p className="role">{testimonial.role}</p>
                  </div>
                </div>
                <hr className="testimonial-divider" />
                <p className="testimonial-text">{testimonial.text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial;
