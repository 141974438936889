import React, { Suspense, lazy } from 'react';
import './BentoGrid.css';

const LazyImage = lazy(() => import('./LazyImage'));

const ImagePlaceholder = () => (
  <div className="image-placeholder">
    <div className="loading-spinner"></div>
  </div>
);

const BentoGrid = () => {
  const gridItems = [
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965540/elysian-inetriors/s2nlvxe1xyunn8lpu8ec.jpg', type: 'featured' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965540/elysian-inetriors/odi5nqztxyykxk6hlbjk.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965538/elysian-inetriors/ffms1ptfldtgg7z1mw2i.jpg', type: 'tall' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965538/elysian-inetriors/prvrjgwc7xstvsgzvmqc.jpg', type: 'wide' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965537/elysian-inetriors/hmsl2xrk9y3twq2chzvq.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965537/elysian-inetriors/wlyolizdn2xzsb756p0a.jpg', type: 'tall' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965536/elysian-inetriors/jwouuuhjijhvarb8ghem.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965535/elysian-inetriors/ezhwdibsoqjstpaqcomn.jpg', type: 'wide' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965531/elysian-inetriors/ekjcmadkbpwxyxq3fezu.jpg', type: 'tall' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965531/elysian-inetriors/pv75gxwvpw6rxstz9ehg.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965529/elysian-inetriors/qq6mgdtcueavwyxnbeoa.jpg', type: 'wide' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965527/elysian-inetriors/xiz6mqxj9ty2zdwf9ji5.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965527/elysian-inetriors/sa1fmcoz6bo9vsp7ovtq.jpg', type: 'tall' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965527/elysian-inetriors/sphq6ylvbqpiq1hni0nc.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965527/elysian-inetriors/mn18szcowo6ncnj18cng.jpg', type: 'featured' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965527/elysian-inetriors/cf2zoxg6uo6xchb5o88p.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965526/elysian-inetriors/henpedqkyqrppq6eidmo.jpg', type: 'tall' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965524/elysian-inetriors/zvi41ohtiixjnb4vytzj.jpg', type: 'wide' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965523/elysian-inetriors/zoi7rhqdpacpynsmdcog.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965523/elysian-inetriors/eosro4ywqgg66ft1ddd4.jpg', type: 'normal' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965522/elysian-inetriors/xtrxxwke1yiwhtmgtnnl.jpg', type: 'wide' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965522/elysian-inetriors/oc337eja4rc8wq9boljy.jpg', type: 'tall' },
    { src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743965522/elysian-inetriors/ei8uryy5gzrn0xvaa1tw.jpg', type: 'normal' }
  ];

  return (
    <div className="bento-container">
       <div className="hero-section">
        <h1 className="hero-title">Crafting Spaces, Creating Stories</h1>
        <p className="hero-subtitle">Elevating interiors with bespoke elegance and timeless design</p>
      </div>
      <div className="bento-grid">
        {gridItems.map((item, index) => (
          <div 
            key={index} 
            className={`bento-item ${item.type}`}
          >
            <Suspense fallback={<ImagePlaceholder />}>
              <LazyImage 
                src={item.src} 
                alt={`Grid item ${index + 1}`}
              />
            </Suspense>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BentoGrid;
