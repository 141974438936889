import React, { lazy, Suspense, useEffect, useState } from 'react';
import { textdb } from '../../firebase'; // Firestore instance
import { collection, onSnapshot } from 'firebase/firestore';
import './Gallery.css';
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Inline lazy-loaded image component using React.lazy
const LazyImage = lazy(() =>
  Promise.resolve({
    default: ({ imageUrl, title, className }) => (
      <LazyLoad 
        height={200} 
        offset={100} 
        once 
        placeholder={
          <div style={{ height: '200px' }}>
            <Skeleton height={200} width={'100%'} />
          </div>
        }
      >
        <img
          src={imageUrl}
          alt={title}
          className={className}
          loading="lazy"
        />
      </LazyLoad>
    ),
  })
);

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(textdb, 'artistry-dental'), (snapshot) => {
      const imagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imagesData);
    });
    return () => unsubscribe();
  }, []);

  // Filter images based on category
  const mediaCoverageImages = images.filter(img => img.category === "Media Coverage");
  const normalImages = images.filter(img => img.category === "Normal Images");

  return (
    <>
      {/* Hero Section */}
      <div className="g-hero-section">
        <div className="g-hero-content">
          <h1 className="g-hero-title">Gallery</h1>
          <p className="g-hero-subtitle"></p>
        </div>
      </div>

      {/* Media Coverage Section */}
      <div className="gallery-container">
        <h2>Media Coverage</h2>
        {mediaCoverageImages.length > 0 ? (
          <div className="gallery-masonry">
            {mediaCoverageImages.map((image) => (
              <div key={image.id} className="gallery-item">
                <Suspense fallback={<Skeleton height={200} width={'100%'} />}>
                  <LazyImage 
                    imageUrl={image.imageUrl} 
                    title={image.title} 
                    className="gallery-image" 
                  />
                </Suspense>
                <div className="gallery-title">{image.title}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>No media coverage images available.</p>
        )}
      </div>

      {/* Normal Images Section */}
      <div className="gallery-container">
        <h2>Gallery Images</h2>
        {normalImages.length > 0 ? (
          <div className="gallery-masonry">
            {normalImages.map((image) => (
              <div key={image.id} className="gallery-item">
                <Suspense fallback={<Skeleton height={200} width={'100%'} />}>
                  <LazyImage 
                    imageUrl={image.imageUrl} 
                    title={image.title} 
                    className="gallery-image" 
                  />
                </Suspense>
                <div className="gallery-title">{image.title}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>No normal images available.</p>
        )}
      </div>
    </>
  );
};

export default Gallery;
