import React from 'react';
import styles from './ContactInfo.module.css';

const ContactInfo = () => {
  const contactCards = [
    {
      icon: "📞",
      title: "Call Us",
      info: "+91 82177 01550",
      link: "tel:+918217701550",
      type: "phone"
    },
    {
      icon: "✉️",
      title: "Send Us Email",
      info: "elysianinteriors@gmail.com",
      link: "mailto:elysianinteriors@gmail.com",
      type: "email"
    },
    // {
    //   icon: "📍",
    //   title: "Location",
    //   info: "Apex Group of Institutions, Kaushalganj, Rampur, Rudrapur",
    //   link: "https://maps.google.com/?q=109,1st Floor, Naren Pearl, Magarpatta Road, Pune, Maharashtra 411028",
    //   type: "location"
    // }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {contactCards.map((card, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.icon}>
              <span role="img" aria-label={card.title}>
                {card.icon}
              </span>
            </div>
            <h3 className={styles.title}>{card.title}</h3>
            {card.type === 'location' ? (
              <p className={styles.details}>
                <a 
                  href={card.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  {card.info}
                </a>
              </p>
            ) : (
              <p className={styles.details}>
                <a 
                  href={card.link}
                  className={styles.link}
                >
                  {card.info}
                </a>
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactInfo; 