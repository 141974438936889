import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      {/* Top Section with Contact and CTA */}
      <div className="footer-top">
        <div className="footer-top-content">
          <div className="contact-info">
            <div className="contact-item">
              <div className="icon-wrapper">
                <i className="fas fa-phone"></i>
              </div>
              <div className="contact-text">
                <span className="label">Call Us</span>
                <a href="tel:+918217701550">+91 82177 01550</a>
               
              </div>
            </div>
            
            <div className="contact-item">
              <div className="icon-wrapper">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="contact-text">
                <span className="label">Mail Us</span>
                <a href="mailto:elysianinteriors@gmail.com">elysianinteriors@gmail.com</a>
              </div>
            </div>
          </div>

          <div className="slogan">
          Discover Inspired Living Through Design.
          </div>

          <Link to="/contact" className="book-button">
            Book Your Consultency
          </Link>
        </div>
      </div>

      {/* Main Footer Content */}
      <div className="footer-main">
        {/* Logo & Timings */}
        <div className="footer-logo-section">
          <img 
            src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743763443/nblbcs7ctu4lu2glmb2x.png" 
            alt="DentArtistry Logo" 
            className="footer-logo"
          />
          
        </div>

        {/* Social Links */}
        <div className="social-links">
          <a href="https://www.instagram.com/elysianinteriorsofficial?igsh=dDNxajRwcHJuZTB1" target="_blank" rel="noopener noreferrer" className="social-icon instagram">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/share/18wbrsau7g/" target="_blank" rel="noopener noreferrer" className="social-icon facebook">
            <i className="fab fa-facebook"></i>
          </a>
          
        </div>

        {/* Quick Navigation */}
        <div className="services-links">
          <div className="services-row">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
           
            <Link to="/gallery">Gallery</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/service">Services</Link>
          </div>
        </div>

        {/* Reviews */}
        <div className="reviews-section">
          <div className="review-box google">
            <div className="review-count">
              <span className="number">500+</span>
              <span className="text">Google Reviews</span>
            </div>
            <img 
              src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1741854571/dt7yqgvl59loltv7mgrp.png" 
              alt="Google Reviews Badge" 
              className="review-logo"
            />
            <div className="rating">Rated 4.9</div>
            <div className="stars">★★★★★</div>
          </div>
        </div>
      </div>

      {/* Bottom Strip */}
      <div className="footer-bottom">
        <p>
          &copy; {currentYear} Artistry. Developed by{' '}
          <a 
            href="https://anantainfotech.com" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: '#ff5757', textDecoration: 'none' }}
          >
            Ananta Infotech
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
