import React from 'react';
import './WhatsAppButton.css';

const WhatsAppButton = ({ phoneNumber, message }) => {
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <i className="fab fa-whatsapp"></i>
    </button>
  );
};

export default WhatsAppButton;
