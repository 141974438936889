import React from 'react'
import { FaPaintBrush, FaCouch, FaDraftingCompass, FaHeart } from "react-icons/fa";
import './WhyChooseUs.css'
import { Link } from 'react-router-dom'

export default function WhyChooseUs() {
  return (
    <main className="whychooseus-main">
      {/* Hero Section (unchanged) */}
      <section className="whychooseus-hero">
        <div className="whychooseus-hero-container">
          <div className="whychooseus-hero-text">
            <h1 className="whychooseus-title">
            Where every corner tells a story of {" "}
              <span style={{color:"white"}}>elegance, comfort,</span>  and timeless design.
            </h1>
           
          </div>

          {/* Hero Image (replace with your actual image URL) */}
          <div className="whychooseus-hero-image">
          <p className="whychooseus-description">
          At <b>Elysian Interiors</b>, we believe that a truly beautiful space is more than just visually appealing — it’s a reflection of your personality, lifestyle, and dreams. That’s why every corner we design is thoughtfully crafted to tell a story — your story — woven with elements of elegance, comfort, and timeless sophistication. Whether it’s a cozy reading nook, a sleek modular kitchen, or a luxurious living room, our goal is to create spaces that inspire and feel effortlessly yours.
            </p>
           <Link to="contact"> <button className="whychooseus-button">Connect With Us</button></Link>

          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="whychooseus-features">
  <div className="whychooseus-features-container">
    {/* Interior Image */}
    <div className="whychooseus-features-image">
      <img
        src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743959918/ylat0zl1xpumwum0ol96.jpg"
        alt="Elysian Interiors Showcase"
      />
    </div>

    {/* Interior Feature Cards */}
    <div className="whychooseus-features-cards">

      {/* Expert Designers */}
      <div className="feature-card-row">
        <div className="feature-card-icon-container">
          <FaDraftingCompass className="feature-card-icon" />
        </div>
        <div className="feature-card-text">
          <h3 className="feature-card-title">Expert Designers</h3>
          <p>
            Our team of seasoned Architects interior designers brings unmatched creativity and deep industry knowledge. With an eye for detail and passion for design, we curate spaces that reflect your personality and elevate your lifestyle.
          </p>
        </div>
      </div>

      {/* Premium Quality Materials */}
      <div className="feature-card-row">
        <div className="feature-card-icon-container">
          <FaCouch className="feature-card-icon" />
        </div>
        <div className="feature-card-text">
          <h3 className="feature-card-title">Premium Quality Materials</h3>
          <p>
            We use only the finest quality materials sourced from trusted partners to ensure durability, elegance, and timeless appeal in every project we execute.
          </p>
        </div>
      </div>

      {/* Modern Design Technology */}
      <div className="feature-card-row">
        <div className="feature-card-icon-container">
          <FaPaintBrush className="feature-card-icon" />
        </div>
        <div className="feature-card-text">
          <h3 className="feature-card-title">Modern Design Technology</h3>
          <p>
            Leveraging 3D visualization and the latest design tools, we bring your ideas to life with precision. From concept to completion, our tech-driven process ensures clarity and customization at every step.
          </p>
        </div>
      </div>

      {/* Personalized and Warm Service */}
      <div className="feature-card-row">
        <div className="feature-card-icon-container">
          <FaHeart className="feature-card-icon" />
        </div>
        <div className="feature-card-text">
          <h3 className="feature-card-title">Personalized and Warm Service</h3>
          <p>
            We believe every client is unique. From the first consultation to the final reveal, our dedicated team ensures a seamless and stress-free experience, tailored to your vision and lifestyle.
          </p>
        </div>
      </div>

    </div>
  </div>
</section>
    </main>
  )
}
