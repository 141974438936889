import React from 'react'
import BentoGrid from '../BentoGrid/BentoGrid';
import CoreValues from './CoreValues';
import Specialists from './Specialists';
const About = () => {
  return (
    <div className='about-container'>

       
        <CoreValues/>
        
    </div>
  )
}
    
export default About