// PendantLights.js
import React from 'react'; // Removed useState
import styles from './PendantLights.module.css';

// Accept isOn as a prop
function PendantLights({ isOn }) {

  // --- SVG Configuration (remains the same) ---
  const viewBoxWidth = 300;
  const viewBoxHeight = 350;
  const mountRadius = 35;
  const mountCx = viewBoxWidth / 2; // 150
  const mountCy = mountRadius + 5; // 40

  const rings = [
    { id: 1, cx: mountCx, cy: 160, rx: 130, ry: 40, rotate: -10, wirePoints: [{x: mountCx - 15, y: mountCy + mountRadius -5}, {x: mountCx + 15, y: mountCy + mountRadius - 5}, {x: mountCx, y: mountCy + mountRadius - 5}] },
    { id: 2, cx: mountCx, cy: 230, rx: 100, ry: 35, rotate: 15, wirePoints: [{x: mountCx - 10, y: mountCy + mountRadius - 5}, {x: mountCx + 10, y: mountCy + mountRadius - 5}, {x: mountCx + 25, y: mountCy + mountRadius - 5}] },
    { id: 3, cx: mountCx, cy: 290, rx: 60,  ry: 25, rotate: -25, wirePoints: [{x: mountCx - 20, y: mountCy + mountRadius - 5}, {x: mountCx + 5, y: mountCy + mountRadius - 5}, {x: mountCx + 20, y: mountCy + mountRadius - 5}] },
  ];

  const wireAttachmentPoints = {
     1: [{ x: rings[0].cx - 50, y: rings[0].cy - rings[0].ry + 15 }, { x: rings[0].cx + 50, y: rings[0].cy - rings[0].ry + 15 }, { x: rings[0].cx, y: rings[0].cy - rings[0].ry + 10 }],
     2: [{ x: rings[1].cx - 40, y: rings[1].cy - rings[1].ry + 15 }, { x: rings[1].cx + 40, y: rings[1].cy - rings[1].ry + 15 }, { x: rings[1].cx + 10, y: rings[1].cy - rings[1].ry + 10}],
     3: [{ x: rings[2].cx - 25, y: rings[2].cy - rings[2].ry + 10 }, { x: rings[2].cx + 25, y: rings[2].cy - rings[2].ry + 10 }, { x: rings[2].cx, y: rings[2].cy - rings[2].ry + 5 }],
  };

  // Container div removed as layout is handled by parent now
  // Button removed

  return (
      <svg
        className={styles.svgCanvas} // Keep existing styles for the SVG itself
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Ceiling Mount - Stays Stationary */}
        <circle
          className={styles.ceilingMount}
          cx={mountCx}
          cy={mountCy}
          r={mountRadius}
        />

        {/* Group for Moving Parts (Wires and Rings) */}
        <g className={styles.movingParts}>
          {/* Render Rings */}
          {rings.slice().reverse().map((ring) => (
            <g key={ring.id} transform={`rotate(${ring.rotate} ${ring.cx} ${ring.cy})`}>
              {/* Dark part */}
              <ellipse
                className={styles.ringDark}
                cx={ring.cx}
                cy={ring.cy}
                rx={ring.rx}
                ry={ring.ry}
              />
              {/* Light part - uses the passed 'isOn' prop */}
              <ellipse
                className={`${styles.ringLight} ${isOn ? styles.on : styles.off}`}
                cx={ring.cx}
                cy={ring.cy}
                rx={ring.rx}
                ry={ring.ry}
              />
            </g>
          ))}

          {/* Suspension Wires */}
          {rings.map((ring) =>
            wireAttachmentPoints[ring.id].map((attachPoint, wireIndex) => (
              <line
                key={`wire-${ring.id}-${wireIndex}`}
                className={styles.wire}
                x1={ring.wirePoints[wireIndex % ring.wirePoints.length].x}
                y1={ring.wirePoints[wireIndex % ring.wirePoints.length].y}
                x2={attachPoint.x}
                y2={attachPoint.y}
              />
            ))
          )}
        </g>
      </svg>
      // The button previously here is now removed
  );
}

export default PendantLights;

// NOTE: PendantLights.module.css remains the same as the previous step
// (including the glow and sway animation styles).
// We don't need the .pendantContainer or .toggleButton styles in
// PendantLights.module.css anymore if they aren't used elsewhere,
// but keeping them doesn't hurt.