import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your Firebase configuration
const firebaseConfig = {

  apiKey: "AIzaSyAt9p3BGKBeNSF26npq2QELA3GIBMToScU",
  authDomain: "raktdaan-7a169.firebaseapp.com",
  databaseURL: "https://raktdaan-7a169-default-rtdb.firebaseio.com",
  projectId: "raktdaan-7a169",
  storageBucket: "raktdaan-7a169.appspot.com",
  messagingSenderId: "116685109840",
  appId: "1:116685109840:web:e1fa668c364e4c53fd6f55"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);
export const textdb = getFirestore(app);
export const storage = getStorage(app); 
export default app;
