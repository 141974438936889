
import './App.css';
import TestEditor from './Components/Travel/TestEditor';
import { UploadPackages } from './Components/Travel/UploadPackages';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import Login from './Components/User/Login';
import Register from './Components/User/Register';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import { BlogUpload } from './Components/Blog/BlogUpload';
import { DisplayBlog } from './Components/Blog/DisplayBlog';
import { BlogDetails } from './Components/Blog/BlogDetails';
import UploadImages from './Components/Gallery/UploadImages';
import Gallery from './Components/Gallery/Gallery';
import { ContactUs } from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import AppointmentEnquiries from './Components/BookAppointment/AppointmentEnquiries';

import AdminDashboard from './Components/AdminDashboard/AdminDashboard';
import ServiceGrid from './Components/Services/ServiceGrid';
import BentoGrid from './Components/BentoGrid/BentoGrid';
import PendantLights from './Components/Light/PendantLights';
import WhatsAppButton from './Components/WhatsAppButton/WhatsAppButton';
function App() {
  return (
    <div className="App">
    <Navbar/>

   <Routes>
   
   <Route exact path="/login" element={<Login/>} />
   <Route exact path="/" element={<Home/>} />

   <Route exact path="/register" element={<Register/>} />

   <Route path="/blog" element={<DisplayBlog />} />
   <Route path="/blog/:blogId" element={<BlogDetails />} />

   
   <Route exact path="/gallery" element={<BentoGrid/>} />
   <Route exact path="/contact" element={<ContactUs/>} />
   <Route exact path="/about" element={<About/>} />
 
   <Route exact path="/service" element={<ServiceGrid/>} />
   <Route exact path="/light" element={<PendantLights/>} />





 

   <Route path="/booking-data" element={<PrivateRoute>{<AppointmentEnquiries/>} </PrivateRoute>}/>
   <Route path="/upload-gallery" element={<PrivateRoute>{<UploadImages/>} </PrivateRoute>}/>
   <Route path="/upload-blog" element={<PrivateRoute>{<BlogUpload/>} </PrivateRoute>}/>
   <Route path="/dashboard" element={<PrivateRoute>{<AdminDashboard/>} </PrivateRoute>}/>


   </Routes>
   <WhatsAppButton 
        phoneNumber="+918150057419" 
        message="Hello, I would like to know more about your services." 
      />
  <Footer/>
    </div>
  );
}

export default App;
