import React, { useEffect, useState, useRef } from 'react';
import {
  FaProjectDiagram,   // Projects Completed
  FaClock,            // Years of Experience
  FaUsersCog,         // Skilled Workforce
  FaSmile             // Happy Clients
} from 'react-icons/fa';

import './Facts.css';

const factsData = [
  {
    icon: <FaProjectDiagram />,
    target: 500,
    label: 'Projects Completed',
  },
  {
    icon: <FaClock />,
    target: 10,
    label: 'Years of Experience',
  },
  {
    icon: <FaUsersCog />,
    target: 250,
    label: 'Skilled Professionals',
  },
  {
    icon: <FaSmile />,
    target: 3000,
    label: 'Happy Clients',
  },
];

const Facts = () => {
  return (
    <div className="facts-container">
      {factsData.map((item, index) => (
        <FactItem
          key={index}
          icon={item.icon}
          target={item.target}
          label={item.label}
        />
      ))}
    </div>
  );
};

const FactItem = ({ icon, target, label }) => {
  const [count, setCount] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const itemRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            setHasAnimated(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, [hasAnimated]);

  useEffect(() => {
    if (hasAnimated) {
      let start = 0;
      const duration = 2000;
      const stepTime = Math.max(Math.floor(duration / target), 10);

      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start >= target) {
          clearInterval(timer);
        }
      }, stepTime);

      return () => clearInterval(timer);
    }
  }, [hasAnimated, target]);

  return (
    <div className="fact-item" ref={itemRef}>
      <div className="fact-icon">{icon}</div>
      <div className="fact-info">
        <div className="fact-count">{count}+</div>
        <div className="fact-label">{label}</div>
      </div>
    </div>
  );
};

export default Facts;
