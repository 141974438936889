import React, { useEffect, useRef, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { storage, textdb, auth } from '../../firebase';
import { doc, setDoc, collection, getDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './BlogUpload.css';

const generateSlug = (text) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const BlogUpload = () => {
  // Firebase Auth: get the current user
  const [user] = useAuthState(auth);

  // Quill editor
  const quillRef = useRef(null);
  const [quillInstance, setQuillInstance] = useState(null);

  // Form fields
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [iconImage, setIconImage] = useState(null);
  const [iconImagePreview, setIconImagePreview] = useState(null);
  const [editBlogId, setEditBlogId] = useState(null);
  
  // Uploading state to track the upload progress
  const [uploading, setUploading] = useState(false);

  // Fetch blog posts from Firestore (collection: Blogs)
  const [blogPosts, blogLoading, blogError] = useCollection(collection(textdb, 'Blogs'));

  // Initialize Quill editor
  useEffect(() => {
    if (quillRef.current && !quillInstance) {
      const quill = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['clean'],
          ],
        },
      });
      quill.on('text-change', () => {
        setContent(quill.root.innerHTML);
      });
      setQuillInstance(quill);
    }
  }, [quillInstance]);

  // Icon image preview
  useEffect(() => {
    if (iconImage) {
      const url = URL.createObjectURL(iconImage);
      setIconImagePreview(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setIconImagePreview(null);
    }
  }, [iconImage]);

  const handleIconImageChange = (e) => {
    if (e.target.files.length > 0) {
      setIconImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!title || !description || !content || (!iconImage && !editBlogId)) {
      toast.error('Please fill in all fields');
      return;
    }
    if (!user) {
      toast.error('You must be logged in to upload a blog post');
      return;
    }

    setUploading(true);
    toast.info('Processing...', { autoClose: 3000 });
    try {
      let iconImageUrl;
      if (iconImage) {
        const iconImageRef = ref(storage, `Blog_icons/${iconImage.name}`);
        await uploadBytes(iconImageRef, iconImage);
        iconImageUrl = await getDownloadURL(iconImageRef);
      }
      const timestamp = new Date();
      const slug = generateSlug(title);
      const blogRef = editBlogId
        ? doc(textdb, 'Blogs', editBlogId)
        : doc(collection(textdb, 'Blogs'));

      // If editing, retain previous icon if new one isn’t provided.
      if (editBlogId) {
        const currentDoc = await getDoc(blogRef);
        const currentData = currentDoc.exists() ? currentDoc.data() : {};
        if (!iconImage) iconImageUrl = currentData.iconImageUrl;
      }

      await setDoc(blogRef, {
        title,
        slug,
        description,
        content,
        iconImageUrl,
        timestamp,
        author: user.displayName || user.email,
      });

      toast.success(editBlogId ? 'Blog updated successfully!' : 'Blog uploaded successfully!');

      // Reset fields
      setTitle('');
      setDescription('');
      setContent('');
      setIconImage(null);
      setEditBlogId(null);
      if (quillInstance) quillInstance.setContents([]);
    } catch (error) {
      console.error('Error processing blog post:', error);
      toast.error('Error processing blog post');
    } finally {
      setUploading(false);
    }
  };

  const handleEdit = (blog) => {
    setTitle(blog.title);
    setDescription(blog.description);
    setContent(blog.content);
    setEditBlogId(blog.id);
    if (quillInstance) {
      quillInstance.root.innerHTML = blog.content;
    }
  };

  const handleDelete = async (blogId, iconImageUrl) => {
    try {
      const blogRef = doc(textdb, 'Blogs', blogId);
      await deleteDoc(blogRef);
      if (iconImageUrl) {
        const iconImagePath = decodeURIComponent(
          iconImageUrl.split('/o/')[1].split('?')[0]
        );
        const iconImageRef = ref(storage, iconImagePath);
        await deleteObject(iconImageRef);
      }
      toast.success('Blog deleted successfully!');
    } catch (error) {
      console.error('Error deleting blog:', error);
      toast.error('Error deleting blog');
    }
  };

  return (
    <div className="blogupload-upload-container">
      <div className="blogupload-form">
        <h2 className="blogupload-form__title">
          {editBlogId ? 'Edit Blog Post' : 'Upload Blog Post'}
        </h2>

        {/* Row 1: Title & Description */}
        <div className="blogupload-row">
          <div className="blogupload-field-half">
            <label className="blogupload-label">Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="blogupload-input"
              placeholder="Enter title"
            />
          </div>
          <div className="blogupload-field-half">
            <label className="blogupload-label">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="blogupload-input"
              placeholder="Enter description"
            />
          </div>
        </div>

        {/* Row 2: Icon Image */}
        <div className="blogupload-row">
          <div className="blogupload-field-half">
            <label className="blogupload-label">Icon Image:</label>
            <div className="blogupload-file-input-wrapper">
              <input
                type="file"
                onChange={handleIconImageChange}
                className="blogupload-input-file"
                id="icon-image-input"
              />
              <label htmlFor="icon-image-input" className="blogupload-file-input-label">
                {iconImage ? iconImage.name : 'Choose File'}
              </label>
            </div>
            {iconImagePreview && (
              <img
                src={iconImagePreview}
                alt="Icon Preview"
                className="blogupload-image-preview"
              />
            )}
          </div>
        </div>

        {/* Row 3: Content */}
        <div className="blogupload-field">
          <label className="blogupload-label">Content:</label>
          <div ref={quillRef} className="blogupload-editor" />
        </div>

        <button 
          className="blogupload-button" 
          onClick={handleUpload} 
          disabled={uploading}
        >
          {uploading 
            ? (editBlogId ? 'Updating...' : 'Uploading...')
            : (editBlogId ? 'Update' : 'Upload')}
        </button>
      </div>

      <hr className="blogupload-divider" />

      <h2 className="blogupload-list-title">Blog Posts</h2>
      {blogLoading && <p className="blogupload-loading">Loading...</p>}
      {blogError && <p className="blogupload-error">Error fetching blog posts</p>}
      {blogPosts && (
        <div className="blogupload-post-list">
          {blogPosts.docs.map((blog) => {
            const data = blog.data();
            return (
              <div key={blog.id} className="blogupload-post-card">
                <img src={data.iconImageUrl} alt="Icon" className="blogupload-post-icon" />
                <p className="blogupload-post-time">
                  {data.timestamp &&
                    new Date(data.timestamp.toDate()).toLocaleString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                </p>
                <h3 className="blogupload-post-title">{data.title}</h3>
                <p className="blogupload-post-desc">{data.description}</p>
                <p className="blogupload-post-desc">
                  <strong>Author:</strong> {data.author}
                </p>
                <button
                  className="blogupload-button-small"
                  onClick={() => handleEdit({ id: blog.id, ...data })}
                >
                  Edit
                </button>
                <button
                  className="blogupload-button-small"
                  onClick={() => handleDelete(blog.id, data.iconImageUrl)}
                >
                  Delete
                </button>
              </div>
            );
          })}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};
