import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TestEditor = () => {
  const [editorData, setEditorData] = useState('<p>Hello from CKEditor 5!</p>');

  return (
    <div>
      <h2>CKEditor 5 Test</h2>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
          console.log({ event, editor, data });
        }}
      />
    </div>
  );
};

export default TestEditor;
