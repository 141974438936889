import React from 'react';
import { Link } from 'react-router-dom';
import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div className="adminDashboardContainer">
      <h1 className="adminDashboardTitle">Admin Dashboard</h1>
      <div className="adminDashboardCardGrid">
        <Link to="/booking-data" className="adminDashboardCard adminDashboardBookingCard">
          <div className="adminDashboardCardIcon">
            <i className="fas fa-calendar-check"></i>
          </div>
          <div className="adminDashboardCardContent">
            <h3 className="adminDashboardCardTitle">Booking Data</h3>
            <p className="adminDashboardCardDescription">
              Manage and review all appointment enquiries.
            </p>
          </div>
        </Link>
        <Link to="/upload-gallery" className="adminDashboardCard adminDashboardGalleryCard">
          <div className="adminDashboardCardIcon">
            <i className="fas fa-images"></i>
          </div>
          <div className="adminDashboardCardContent">
            <h3 className="adminDashboardCardTitle">Upload Gallery</h3>
            <p className="adminDashboardCardDescription">
              Add or update gallery images and media.
            </p>
          </div>
        </Link>
        <Link to="/upload-blog" className="adminDashboardCard adminDashboardBlogCard">
          <div className="adminDashboardCardIcon">
            <i className="fas fa-blog"></i>
          </div>
          <div className="adminDashboardCardContent">
            <h3 className="adminDashboardCardTitle">Upload Blog</h3>
            <p className="adminDashboardCardDescription">
              Create and manage blog posts.
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminDashboard;
