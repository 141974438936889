import React from 'react';
import Lottie from 'lottie-react';
import animationData from './Animation - 1743766490318.json'; 
import './Welcom.css';

const Welcom = () => {
  return (
    <div className="welcome-container">
      <div className="welcome-text">
        <h1>Welcome to Elysian Interiors — <span style={{color:"#045a68"}}> Redefining Luxury,</span> One Room at a Time.</h1>
        <p>
        At Elysian Interiors, we believe your space should be a reflection of who you are—elegant, unique, and full of purpose. Whether you're designing your dream home or elevating your workspace, our team is here to turn your vision into reality with timeless design, thoughtful details, and seamless execution.

<br/> <b>Let’s create interiors that don’t just look beautiful—but feel like home.</b>
        </p>
      </div>
      <div className="welcome-animation">
        <Lottie animationData={animationData} loop={true} />
      </div>
    </div>
  );
};

export default Welcom;
