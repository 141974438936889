import React, { useEffect, useRef } from 'react';
import './ServiceGrid.css'; // We'll modify this CSS file

// Define the structured service data (Update placeholder URLs)
const servicesData = [
  {
    category: "Interior Design",
    // Replace with your actual image URLs or better placeholders
    image: "https://images.unsplash.com/photo-1505691938895-1758d7feb511?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW50ZXJpb3IlMjBkZXNpZ258ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    items: [
      "Residential Design",
      "Commercial Design",
      "Hospitality Design"
    ]
  },
  {
    category: "Architecture",
    image: "https://images.unsplash.com/photo-1487958449943-2429e8be8625?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXJjaGl0ZWN0dXJlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    items: [
      "Residential Architecture",
      "Commercial Architecture",
      "Institutional Architecture"
    ]
  },
  {
    category: "Space Planning",
    image: "https://res.cloudinary.com/ddkyeuhk8/image/upload/v1744257711/njrvkzbhg1gyyyaitsfq.jpg",
    items: [
      "Floor Plan Design",
      "Layout Optimization",
      "Traffic Flow Analysis"
    ]
  },
  {
    category: "3D Visualization",
    image: "https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8M2QlMjByZW5kZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    items: [
      "3D Modeling",
      "Rendering",
      "Animation"
    ]
  },
  {
    category: "Project Management",
    image: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cHJvamVjdCUyMG1hbmFnZW1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    items: [
      "Construction Management",
      "Design Implementation",
      "Budgeting & Scheduling"
    ]
  }
];

// Define the ServiceGrid functional component
const ServiceGrid = () => {
  // Ref to store references to the card elements for the observer
  const cardRefs = useRef([]);

  // Effect hook remains largely the same, targeting the card elements
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1 // Trigger when 10% of the card is visible
      }
    );

    const currentRefs = cardRefs.current.filter(card => card !== null);
    currentRefs.forEach(card => {
       observer.observe(card);
    });

    return () => {
      currentRefs.forEach(card => {
        if (card) {
          observer.unobserve(card);
        }
      });
      observer.disconnect();
    };
  }, []); // Empty dependency array means this runs once on mount

  // Render the component JSX
  return (
    <div className="service-grid-container">
      {/* Main title for the services section */}
      <h2 className="service-grid-title">Services We Provide</h2>

      {/* Container for the service cards (changed class name for clarity) */}
      <div className="service-cards-wrapper">
        {/* Map over the servicesData array */}
        {servicesData.map((service, index) => (
          // Service Card - This is the element we animate
          <div
            key={service.category} // Use category as the key
            className="service-card" // Apply card styling and animation target
            ref={el => (cardRefs.current[index] = el)} // Assign ref to the card
          >
            {/* Card Image */}
            <img
              src={service.image}
              alt={`${service.category} Service`} // Add descriptive alt text
              className="service-card-image"
              loading="lazy" // Add lazy loading for performance
            />

            {/* Card Content Area */}
            <div className="service-card-content">
              {/* Category Title (using h3 for better semantics) */}
              <h3 className="service-card-category">{service.category}</h3>

              {/* List of Sub-Service Items (using ul/li for semantics) */}
              <ul className="service-card-items">
                {service.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="service-card-item">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceGrid;