import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { textdb, auth } from '../../firebase';
import DOMPurify from 'dompurify';
import "./BlogDetails.css";
import { useCollection } from 'react-firebase-hooks/firestore';
import { CiCalendarDate } from "react-icons/ci";
import { FaGreaterThan } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import Load from '../Load/Load';

// Helper function to generate a slug from a title.
const generateSlug = (text) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const BlogDetails = () => {
  // For listing other blog posts in the sidebar.
  const [blogs, loading, error] = useCollection(collection(textdb, 'Blogs'));
  // Extract the slug from the URL parameter.
  const { blogId } = useParams();
  const history = useNavigate();
  const currentUser = auth.currentUser;

  const [blogData, setBlogData] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        // Decode and trim the URL parameter (the slug)
        const decodedSlug = decodeURIComponent(blogId).trim();
        console.log("Decoded slug:", decodedSlug);

        // Query Firestore for a document where the 'slug' field matches the decoded slug.
        const q = query(
          collection(textdb, 'Blogs'),
          where('slug', '==', decodedSlug)
        );
        const querySnapshot = await getDocs(q);
        console.log("Query snapshot size:", querySnapshot.size);

        if (querySnapshot.empty) {
          console.log('No document found for the given slug!');
          setNotFound(true);
        } else {
          // Use the first matching document.
          setBlogData(querySnapshot.docs[0].data());
        }
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };

    fetchBlogDetails();
  }, [blogId]);

  if (notFound) {
    return <div className="blogdetail-notfound">Blog post not found</div>;
  }

  if (!blogData) {
    return <Load />;
  }

  return (
    <div className="blogdetail-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blogData.title}</title>
        <meta name="description" content={blogData.description || ""} />
      </Helmet>
      <div className="blogdetail-hero">
        <div className="blogdetail-breadcrumb">
          <Link to="/" className="blogdetail-breadcrumb-link">
            <li className="blogdetail-breadcrumb-item">Home</li>
          </Link>
          <span className="blogdetail-breadcrumb-separator"><FaGreaterThan /></span>
          <Link to="/blog" className="blogdetail-breadcrumb-link">
            <li className="blogdetail-breadcrumb-item">Blog</li>
          </Link>
          <span className="blogdetail-breadcrumb-separator"><FaGreaterThan /></span>
          <li className="blogdetail-breadcrumb-active">{blogData.title}</li>
        </div>
        <div className="blogdetail-title-hero">
          <h1 className="blogdetail-title">{blogData.title}</h1>
          {/* Optionally add action buttons here */}
        </div>
      </div>

      <div className="blogdetail-content">
        <div className="blogdetail-main">
          <div className="blogdetail-image-container">
            <img
              loading="lazy"
              src={blogData.iconImageUrl}
              alt={blogData.title}
              className="blogdetail-image"
            />
          </div>
          <div className="blogdetail-text">
            <h2 className="blogdetail-subtitle">{blogData.title}</h2>
            {/* Display author name */}
            <p className="blogdetail-author">By: {blogData.author}</p>
            <p className="blogdetail-description">{blogData.description}</p>
            <div
              className="blogdetail-body"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blogData.content) }}
            ></div>
          </div>
        </div>

        <div className="blogdetail-sidebar">
          <h2 className="blogdetail-sidebar-title">Other Posts</h2>
          <div className="blogdetail-sidebar-list">
            {loading && <p>Loading...</p>}
            {error && <p>Error fetching posts</p>}
            {blogs && (
              blogs.docs.map((docItem) => {
                const data = docItem.data();
                const slug = data.slug ? data.slug : generateSlug(data.title);
                return (
                  <Link
                    key={docItem.id}
                    to={`/blog/${slug}`}
                    className="blogdetail-sidebar-link"
                  >
                    <div className="blogdetail-card">
                      <img
                        className="blogdetail-card-image"
                        src={data.iconImageUrl}
                        alt={data.title}
                      />
                      <div className="blogdetail-card-data">
                        <h3 className="blogdetail-card-title">{data.title}</h3>
                        <p className="blogdetail-card-time">
                          <span className="blogdetail-card-icon">
                            <CiCalendarDate />
                          </span>
                          {data.timestamp &&
                            new Date(data.timestamp.toDate()).toLocaleString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                            })}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
