import React, { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { textdb, auth } from '../../firebase';
import { collection } from 'firebase/firestore';
import './DisplayBlog.css';
import { Link, useNavigate } from 'react-router-dom';
import { CiCalendarDate } from 'react-icons/ci';
import { Helmet } from 'react-helmet';
import { FaArrowRight } from 'react-icons/fa';
import Load from '../Load/Load';

// Helper function to generate a slug (same as in BlogUpload)
const generateSlug = (text) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const DisplayBlog = () => {
  const history = useNavigate();
  const currentUser = auth.currentUser;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch blog posts from Firestore (collection: Blogs)
  const [blogs, loading, error] = useCollection(collection(textdb, 'Blogs'));

  // Truncate a text string to a specified maximum word count.
  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
    return words.length > maxLength ? words.slice(0, maxLength).join(' ') + '...' : text;
  };

  return (
    <div className="blogdisplay-container">
      <Helmet>
        <title>Blog Posts</title>
        <meta name="description" content="Read our latest blog posts" />
      </Helmet>
      <div className="blogdisplay-heading">
        <h1 className="blogdisplay-title">Blog Posts</h1>
        <p className="blogdisplay-subtitle">Bite-Sized Tips</p>
      </div>
      {loading && <Load />}
      {error && <p>Error fetching blog posts</p>}
      {blogs && (
        <div className="blogdisplay-list">
          {blogs.docs.map((doc) => {
            const data = doc.data();
            // Use stored slug or generate one on the fly.
            const slug = data.slug ? data.slug : generateSlug(data.title);
            return (
              <Link
                key={doc.id}
                to={`/blog/${slug}`}
                className="blogdisplay-link"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <div className="blogdisplay-card">
                  <img
                    loading="lazy"
                    className="blogdisplay-card-image"
                    src={data.iconImageUrl}
                    alt={data.title}
                  />
                  <div className="blogdisplay-card-content">
                    <h3 className="blogdisplay-card-title">{data.title}</h3>
                    <p className="blogdisplay-card-time">
                      <CiCalendarDate className="blogdisplay-card-icon" />
                      {data.timestamp &&
                        new Date(data.timestamp.toDate()).toLocaleString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                    </p>
                    {/* Display the blog author's name */}
                    <p className="blogdisplay-card-author">By: {data.author}</p>
                    <p className="blogdisplay-card-description">
                      {truncateText(data.description, 20)}
                    </p>
                    <div className="blogdisplay-card-arrow">
                      <FaArrowRight />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
