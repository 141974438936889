import React, { useState, useEffect } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { textdb } from '../../firebase'; 
import './ContactUs.css';
import { toast, ToastContainer } from 'react-toastify'; // Make sure to import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Add this import for the styles
import { Helmet } from 'react-helmet';
import BookAppointment from '../BookAppointment/BookAppointment';
import ContactInfo from './ContactInfo';
export const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [quoteDetails, setQuoteDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    program: '', // New field for Program
    city: '',    // New field for City
  });

  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuoteDetails({ ...quoteDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    try {
      // Add a timestamp to the form data
      const dataWithTimestamp = {
        ...quoteDetails,
        timestamp: Timestamp.fromDate(new Date()), // Add Firestore-compatible timestamp
      };

      const docRef = await addDoc(collection(textdb, 'quotes'), dataWithTimestamp);
      console.log('Document written with ID: ', docRef.id);

      // Reset form after successful submission
      setQuoteDetails({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        program: '',
        city: '',
      });

      toast.success('Message Submitted Successfully!'); // This will show the success toast
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Something went wrong. Please try again.'); // This will show the error toast
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className='contact-page'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="We're Always Eager to Hear From You!" />
      </Helmet>
        <div >


        </div>
        <ContactInfo/>
      <BookAppointment/>
    
      {/* <div className='contact-map'>
        <iframe
          width="100%"
          height="650px"
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1d3430.0467182425045!2d76.729272!3d30.717087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef1c7b7a1373%3A0xb38717e731f2ab9f!2sArtistry%20Dental%20Practice!5e0!3m2!1sen!2sin!4v1742200991610!5m2!1sen!2sin"
          className='contact-map-iframe'
        ></iframe>
      </div> */}

      <ToastContainer /> {/* Add ToastContainer here to show toasts */}
    </div>
  );
};
