import React from 'react';
import './CoreValues.css';

const CoreValues = () => {
  const values = [
    {
      icon: "💡",
      title: "Innovation",
      description: "We embrace creativity and forward-thinking in every design we create, staying ahead of trends while maintaining timeless elegance."
    },
    {
      icon: "🎯",
      title: "Integrity",
      description: "We believe in honest communication and ethical practices, ensuring transparency with our clients at every step."
    },
    {
      icon: "🤝",
      title: "Commitment",
      description: "Our team is committed to delivering exceptional results with attention to detail and client satisfaction at the core."
    },
    {
      icon: "✨",
      title: "Excellence",
      description: "We pursue excellence in craftsmanship and service, turning every space into a luxurious expression of personality."
    }
  ];

  return (
    <div className="core-values-container">
      <div className="core-values-content">

        <h2 className="core-values-title">Discover Our Essence</h2>
        <p className="core-values-description">
          At Elysian Interiors, we don’t just design spaces – we craft experiences. Our mission, vision, and values serve as the blueprint for the transformative environments we deliver.
        </p>

        <div className="mission-vision-boxes">
          <div className="mv-box">
            <h3 className="mv-title">Our Vision</h3>
            <p className="mv-text">
              To be the most trusted and sought-after interior design brand, known for redefining luxury and personalized living.
            </p>
          </div>
          <div className="mv-box">
            <h3 className="mv-title">Our Mission</h3>
            <p className="mv-text">
              To deliver tailor-made design solutions that blend functionality with aesthetics, using premium materials and innovative ideas to reflect each client’s unique identity.
            </p>
          </div>
        </div>

        <div className="values-grid">
          {values.map((value, index) => (
            <div key={index} className="value-card">
              <div className="value-icon" role="img" aria-label={value.title}>
                {value.icon}
              </div>
              <h3 className="value-title">{value.title}</h3>
              <p className="value-description">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
