import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

import { CiGrid31 ,CiGrid32} from "react-icons/ci";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => setMenuOpen(prev => !prev);
  const closeMenu = () => setMenuOpen(false);

  // Close menu when clicking outside the navbar container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest('.navbar-container')) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuOpen]);

  // Toggle navbar style based on scroll position (50px threshold)
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close the menu on route change
  useEffect(() => {
    closeMenu();
  }, [location]);

  const handleLinkClick = () => {
    if (window.innerWidth < 768) closeMenu();
  };

  return (
    <nav className={`navbar ${isScrolled ? 'active' : ''}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img
            src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1743763443/nblbcs7ctu4lu2glmb2x.png"
            alt="logo"
          />
        </Link>
        <div className="to-hire">
          <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            {menuOpen ? <CiGrid31 /> : <CiGrid32 />}
          </div>
        </div>
        <ul className={`nav-menu ${menuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={handleLinkClick}>Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link" onClick={handleLinkClick}>About Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/service" className="nav-link" onClick={handleLinkClick}>Services</Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/blog" className="nav-link" onClick={handleLinkClick}>Blog</Link>
          </li> */}
          <li className="nav-item">
            <Link to="/gallery" className="nav-link" onClick={handleLinkClick}>Gallery</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link" onClick={handleLinkClick}>Contact Us</Link>
          </li>
          {/* <li className="m-number-btn-main">
            <a href="tel:+919915070078" className="m-number-btn" onClick={handleLinkClick}>
              +919915070078
            </a>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
