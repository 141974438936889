import React, { useEffect, useState } from 'react';
import { textdb } from '../../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import * as XLSX from 'xlsx';
import './AppointmentEnquiries.css';

const AppointmentEnquiries = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(textdb, 'appointments'));
      const data = querySnapshot.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      setAppointments(data);
    } catch (error) {
      console.error("Error fetching appointments", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const handleDownloadExcel = () => {
    // Convert the appointment data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(appointments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");
    // Trigger the download of the Excel file
    XLSX.writeFile(workbook, "appointments.xlsx");
  };

  const handleDeleteAll = async () => {
    if (window.confirm("Are you sure you want to delete all appointments?")) {
      try {
        // Loop through each appointment and delete it from Firestore
        for (const appointment of appointments) {
          await deleteDoc(doc(textdb, 'appointments', appointment.id));
        }
        setAppointments([]);
        alert("All appointments have been deleted.");
      } catch (error) {
        console.error("Error deleting appointments", error);
        alert("Error deleting appointments. Please try again.");
      }
    }
  };

  return (
    <div className="appointment-enquiries-container">
      <h2>Appointment Enquiries</h2>
      <div className="action-buttons">
        <button onClick={handleDownloadExcel}>Download as Excel</button>
        <button onClick={handleDeleteAll}>Delete All</button>
      </div>
      {loading ? (
        <p>Loading appointments...</p>
      ) : (
        <table className="appointment-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Submitted At</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map(app => (
              <tr key={app.id}>
                <td>{app.name}</td>
                <td>{app.email}</td>
                <td>{app.phone}</td>
                <td>
                  {app.createdAt
                    ? new Date(app.createdAt.seconds * 1000).toLocaleString()
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AppointmentEnquiries;
