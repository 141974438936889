import React from 'react'
import Carousel from '../Carousel/Carousel'
import Welcom from '../Welcom/Welcom'
import Testimonials from '../Testimonial/testimonials'
import WhyChooseUs from '../WhyChooseUs/WhyChooseUs'
import Facts from '../Facts/Facts'
import ServiceGrid from '../Services/ServiceGrid'
import BookAppointment from '../BookAppointment/BookAppointment'
import LightDisplay from '../Light/LightDisplay'
const Home = () => {
  return (
    <div>
        <Carousel/>
        <Welcom/>
        <WhyChooseUs/>
        <Facts/>
        <ServiceGrid/>
        <Testimonials/>
        <BookAppointment/>
        <LightDisplay/>

    </div>
  )
}

export default Home