import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

const Carousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeSlide, setActiveSlide] = useState(0);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Track mouse movement for micro interactions
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  // Track scroll for micro interactions
  useEffect(() => {
    const handleScroll = () => setScrollPos(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Define different decorative SVG elements
  const svgElements = {
    1: (
      // Blueprint Grid: a rectangle with grid lines
      <svg width="60" height="60" viewBox="0 0 64 64" fill="none">
        <rect x="4" y="4" width="56" height="56" stroke="#e6c658" strokeWidth="2" />
        <line x1="4" y1="24" x2="60" y2="24" stroke="#e6c658" strokeWidth="1" />
        <line x1="4" y1="44" x2="60" y2="44" stroke="#e6c658" strokeWidth="1" />
        <line x1="24" y1="4" x2="24" y2="60" stroke="#e6c658" strokeWidth="1" />
        <line x1="44" y1="4" x2="44" y2="60" stroke="#e6c658" strokeWidth="1" />
      </svg>
    ),
    2: (
      // Circle Element: represents a modern fixture
      <svg width="60" height="60" viewBox="0 0 64 64" fill="none">
        <circle cx="32" cy="32" r="26" stroke="#e6c658" strokeWidth="3" />
      </svg>
    ),
    3: (
      // Triangle: can evoke rooflines or modern angles
      <svg width="60" height="60" viewBox="0 0 64 64" fill="none">
        <polygon points="32,8 56,56 8,56" fill="none" stroke="#e6c658" strokeWidth="3" />
      </svg>
    ),
    4: (
      // Star: an accent element
      <svg width="60" height="60" viewBox="0 0 64 64" fill="none">
        <polygon 
          points="32,6 38,26 60,26 42,40 48,60 32,48 16,60 22,40 4,26 26,26" 
          fill="none" 
          stroke="#e6c658" 
          strokeWidth="2" 
        />
      </svg>
    ),
    5: (
      // Wavy line: reminiscent of modern rug designs
      <svg width="60" height="60" viewBox="0 0 64 64" fill="none">
        <path d="M4,32 C16,12 48,52 60,32" stroke="#e6c658" strokeWidth="3" fill="none" />
      </svg>
    ),
    6: (
      // Zigzag: modern and playful
      <svg width="60" height="60" viewBox="0 0 64 64" fill="none">
        <polyline points="4,20 12,40 20,20 28,40 36,20 44,40 52,20 60,40" stroke="#e6c658" strokeWidth="2" fill="none" />
      </svg>
    )
  };

  // Define different decorative SVG settings
  const decorators = [
    { id: 1, className: 'svg-decorator-1', multiplier: 0.02, style: { top: '10%', left: '10%' } },
    { id: 2, className: 'svg-decorator-2', multiplier: 0.03, style: { top: '10%', right: '10%' } },
    { id: 3, className: 'svg-decorator-3', multiplier: 0.02, style: { bottom: '10%', left: '10%' } },
    { id: 4, className: 'svg-decorator-4', multiplier: 0.03, style: { bottom: '10%', right: '10%' } },
    { id: 5, className: 'svg-decorator-5', multiplier: 0.015, style: { top: '50%', left: '5%' } },
    { id: 6, className: 'svg-decorator-6', multiplier: 0.015, style: { bottom: '50%', right: '5%' } },
  ];

  // Compute micro interaction style for each SVG element
  const getSvgStyle = (multiplier) => ({
    transform: `translate(${(mousePos.x - window.innerWidth / 2) * multiplier}px, ${
      (mousePos.y - window.innerHeight / 2) * multiplier + scrollPos * 0.1
    }px)`
  });

  // Replace image URLs with interior design images or appropriate placeholders
  const desktopImages = [
    {
      src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1744273089/elysian-inetriors/jx1ym1bcey4csoof464j.png',
      heading: (
        <>
          Transform Your Space with <br /> Timeless Elegance
        </>
      ),
      text: 'Luxury interiors designed for comfort, functionality, and style. Let’s bring your dream home to life.',
      buttonLabel: 'Explore Our Designs',
    },
    {
      src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1744273098/elysian-inetriors/hulvf4are9krwqhms6nu.png',
      heading: (
        <>
          Where Innovation <br /> Meets Interior Design
        </>
      ),
      text: 'From modular kitchens to smart automation, we craft spaces that redefine modern living.',
      buttonLabel: 'Explore Our Designs',
    },
  ];

  const mobileImages = [
    {
      src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1744272652/elysian-inetriors/epbjynqi1agauzuuqge0.jpg',
      heading: (
        <>
          Transform Your Space with <br /> Timeless Elegance
        </>
      ),
      text: 'Luxury interiors designed for comfort, functionality, and style.',
      buttonLabel: 'Explore Our Designs',
    },
    {
      src: 'https://res.cloudinary.com/ddkyeuhk8/image/upload/v1744272655/elysian-inetriors/p8tjb90rm1dcua3eovlx.jpg',
      heading: (
        <>
          Where Innovation <br /> Meets Interior Design
        </>
      ),
      text: 'We craft spaces that redefine modern living.',
      buttonLabel: 'Explore Our Designs',
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    afterChange: current => setActiveSlide(current),
  };

  const imagesToUse = isMobile ? mobileImages : desktopImages;

  return (
    <div className="carousel-container">
      {/* Render multiple decorative SVGs */}
      {decorators.map(deco => (
        <div
          key={deco.id}
          className={`svg-decorator ${deco.className}`}
          style={{ ...deco.style, ...getSvgStyle(deco.multiplier) }}
        >
          {svgElements[deco.id]}
        </div>
      ))}

      <Slider {...settings}>
        {imagesToUse.map((image, index) => (
          <div key={index} className="carousel-slide">
            <div className="carousel-overlay" />
            <img src={image.src} alt={`Slide ${index}`} />
            <div className={`carousel-text-overlay ${activeSlide === index ? 'active' : ''}`}>
              <h2>{image.heading}</h2>
              <p>{image.text}</p>
              <Link to="/contact">
                <button className="carousel-button">{image.buttonLabel}</button>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
